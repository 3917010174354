<template>
    <div class="home-page ">
        <u-animate-container >
<!--            <u-animate-->
<!--                    name="animate__fadeIn"-->
<!--                    delay="0s"-->
<!--                    duration="1s"-->
<!--                    :iteration="1"-->
<!--                    :offset="0"-->
<!--                    animateClass="animated"-->
<!--            >-->

<!--            </u-animate>-->

            <u-animate
                    name="animate__fadeIn"
                    delay="0s"
                    duration="2s"
                    :iteration="1"
                    :offset="0"
                    animateClass="animated"
            >
                <div class="header">
                    <div class="container">
                        <div class="head-block">
                            <u-animate
                                    class="left"
                                    name="animate__backInLeft"
                                    delay="0s"
                                    duration="1s"
                                    :iteration="1"
                                    :offset="0"
                                    animateClass="animated"
                            >
                                    <img src="/dist/images/left-img.webp" alt="">
                                    <p>{{__('Order 2 bottles of water and receive')}} <span>{{__('pump for 1 zł')}}</span></p>
                            </u-animate>

                            <div class="center">
                                <div class="top">
                                    <img src="/dist/images/logo-header.svg" alt="">
                                    <p>{{__('Water delivery to Poznań')}}</p>
                                </div>
                                <div class="body">
                                    <u-animate
                                            class="circle-1 circle-all"
                                            name="animate__jello"
                                            delay="1s"
                                            duration="1s"
                                            :iteration="1"
                                            :offset="0"
                                            animateClass="animated"
                                    >
                                    </u-animate>
                                    <u-animate
                                            class="circle-2 circle-all"
                                            name="animate__shakeY"
                                            delay="1s"
                                            duration="1s"
                                            :iteration="1"
                                            :offset="0"
                                            animateClass="animated"
                                    >
                                    </u-animate>
                                    <u-animate
                                            class="circle-3 circle-all"
                                            name="animate__swing"
                                            delay="1s"
                                            duration="1s"
                                            :iteration="1"
                                            :offset="0"
                                            animateClass="animated"
                                    >
                                    </u-animate>
                                    <u-animate
                                            class="circle-4 circle-all"
                                            name="animate__tada"
                                            delay="1s"
                                            duration="1s"
                                            :iteration="1"
                                            :offset="0"
                                            animateClass="animated"
                                    >
                                    </u-animate>
                                    <u-animate
                                            class="circle-5 circle-all"
                                            name="animate__heartBeat"
                                            delay="1s"
                                            duration="1s"
                                            :iteration="1"
                                            :offset="0"
                                            animateClass="animated"
                                    >
                                    </u-animate>

                                    <img v-bind:src="isWide ? wideImageUrl : narrowImageUrl" alt="">
                                </div>
                            </div>
                            <u-animate
                                    name="animate__backInRight"
                                    delay="0s"
                                    duration="1s"
                                    :iteration="1"
                                    :offset="0"
                                    animateClass="animated"
                            >
                                <div class="right">
                                    <div class="block">
                                        <p class="text">{{__('Acceptance of orders:')}}</p>
                                        <p class="day">{{__('Mon-Fri: 9:00 - 18:00')}}</p>
                                        <p class="day">{{__('Sat: 10:00 - 13:00')}}</p>
                                        <p class="day">{{__('Sun: closed')}}</p>
                                    </div>
                                    <div class="text-block">
                                        <p>{{__('Free shipping')}}</p>
                                        <a href="tel:+48 510 002 009" class="btn-white" onclick="gtag_report_conversion()">+48 510-002-009</a>
                                    </div>
                                </div>
                            </u-animate>
                        </div>
                        <div class="btn-block">
                            <a class="store" href="itms-apps://apps.apple.com/ua/app/zdrowa-woda/id6473904172?l=ru" onclick="gtag_report_conversion()">
                                <img src="/dist/images/store.svg" alt="">
                                <p>{{__('Download on the')}}<span>App Store</span></p>
                            </a>
                            <a class="store store-1" href="https://play.google.com/store/apps/details?id=com.zdrowawoda.zdrowawoda" onclick="gtag_report_conversion()">
                                <img src="/dist/images/store-1.svg" alt="">
                                <p>{{__('GET IT ON')}}<span>Google Play</span></p>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="stat " >
                    <div class="container">
                        <ul>
                            <li>
                                <u-animate
                                        name="animate__fadeIn"
                                        delay="0s"
                                        duration="0s"
                                        :iteration="1"
                                        :offset="0"
                                        animateClass="animated"
                                >
                                    <div class="img">
                                        <img src="/dist/images/stat-1.svg" alt="">
                                    </div>
                                    <p>{{__('Espresso delivery day after day')}}</p>
                                </u-animate>

                            </li>
                            <li>
                                <u-animate
                                        name="animate__fadeIn"
                                        delay="0s"
                                        duration="1s"
                                        :iteration="1"
                                        :offset="0"
                                        animateClass="animated"
                                >
                                    <div class="img">
                                        <img src="/dist/images/stat-2.svg" alt="">
                                    </div>
                                    <p>{{__('We deliver from one bottle in Poznań')}}</p>
                                </u-animate>

                            </li>
                            <li>
                                <u-animate
                                        name="animate__fadeIn"
                                        delay="0s"
                                        duration="2s"
                                        :iteration="1"
                                        :offset="0"
                                        animateClass="animated"
                                >
                                    <div class="img">
                                        <img src="/dist/images/stat-3.svg" alt="">
                                    </div>
                                    <p>{{__('Free delivery. You only pay for the goods')}}</p>
                                </u-animate>

                            </li>
                            <li>
                                <u-animate
                                        name="animate__fadeIn"
                                        delay="0s"
                                        duration="3s"
                                        :iteration="1"
                                        :offset="0"
                                        animateClass="animated"
                                >
                                    <div class="img">
                                        <img src="/dist/images/stat-4.svg" alt="">
                                    </div>
                                    <p>{{__('A variety of constantly running Actions')}}</p>
                                </u-animate>

                            </li>
                        </ul>
                        <div class="body">
                            <u-animate
                                    class="left"
                                    name="animate__zoomIn"
                                    delay="0s"
                                    duration="1s"
                                    :iteration="1"
                                    :offset="0"
                                    animateClass="animated"
                            >
                                <p>{{__('Extremely clean, tasty and healthy mineral water')}}</p>
                            </u-animate>
                            <div class="right">
                                <u-animate
                                        name="animate__backInRight"
                                        delay="0s"
                                        duration="1s"
                                        :iteration="1"
                                        :offset="0"
                                        animateClass="animated"
                                >
                                    <img src="/dist/images/icon-block.svg" alt="">
                                    <p>{{__('easy')}}</p>
                                </u-animate>
                                <u-animate
                                        name="animate__backInRight"
                                        delay="0s"
                                        duration="2s"
                                        :iteration="1"
                                        :offset="0"
                                        animateClass="animated"
                                >
                                    <img src="/dist/images/icon-block.svg" alt="">
                                    <p>{{__('useful')}}</p>
                                </u-animate>
                                <u-animate
                                        name="animate__backInRight"
                                        delay="0s"
                                        duration="3s"
                                        :iteration="1"
                                        :offset="0"
                                        animateClass="animated"
                                >
                                    <img src="/dist/images/icon-block.svg" alt="">
                                    <p>{{__('preferably')}}</p>
                                </u-animate>
                            </div>



                        </div>
                    </div>
                </div>

                <div class="price" v-if="data">
                    <div class="container">
                        <h2>{{__('The price of natural artesian water with delivery')}}</h2>
                        <div class="price-top">
                            <div class="tabs" v-for="category in data.category" :key="category.id" @click="selectCategory(category)"  :class="{ 'active': category === selectedCategory }">
                                {{ category.name }}
                            </div>
                        </div>

                        <ul v-if="selectedCategory">
                            <li v-for="product in selectedCategory.products" :key="product.id">
<!--                                {{product}}-->
                                <div class="img">
                                    <img :src="product.img" alt="Product Image"/>
                                </div>
                                <p class="name">{{ getProductNameByLanguage(product) }}</p>
                                <p class="desc">{{ getProductDescriptionByLanguage(product) }}</p>
                                <p class="prices">{{ product.price }} zł</p>
                            </li>
                        </ul>

                        <p class="text-block">{{__('We would like to inform you that our bottles are intended for repeated use. This means that on your next order we will collect empty bottles and exchange them for full ones. A one-time deposit is charged for each bottle')}} <span>{{__('30 PLN.')}}</span> {{__('This deposit will be refunded to you if you decide to end your relationship with us and return the bottles to us.')}}</p>
                    </div>
                </div>

                <div class="clients">
                    <v-parallax height="280" src="/dist/images/bg-clients.webp">
                        <div class="container">
                            <u-animate
                                    name="animate__fadeInLeft"
                                    delay="0s"
                                    duration="1s"
                                    :iteration="1"
                                    :offset="0"
                                    animateClass="animated"
                            >
                                <p>{{__('Individual corporate clients and loyal prices')}}</p>
                            </u-animate>

                            <u-animate
                                    name="animate__fadeInRight"
                                    delay="0s"
                                    duration="1s"
                                    :iteration="1"
                                    :offset="0"
                                    animateClass="animated"
                            >
                                <a href="tel:+48 510 002 009" class="btn-site-white" onclick="gtag_report_conversion()">
                                    <span>
                                        <img src="/dist/images/btn-img-white.webp" alt="">
                                        {{__('Discuss individual conditions')}}
                                    </span>
                                </a>
                            </u-animate>

                        </div>
                    </v-parallax>
                </div>

                <div class="work">
                    <div class="container">
                        <h2>{{__('How we work')}}</h2>
                        <ul class="work-stat">
                            <li>
                                <u-animate
                                        name="animate__fadeIn"
                                        delay="0s"
                                        duration="1s"
                                        :iteration="1"
                                        :offset="0"
                                        animateClass="animated"
                                >
                                    <img src="/dist/images/work-1.svg" alt="">
                                    <p class="text">{{__('Acceptance of orders:')}}</p>
                                    <p class="day">{{__('Mon-Fri: 9:00 - 18:00')}}</p>
                                    <p class="day">{{__('Sat: 10:00 - 13:00')}}</p>
                                    <p class="day">{{__('Sun: closed')}}</p>
                                </u-animate>
                            </li>
                            <li>
                                <u-animate
                                        name="animate__fadeIn"
                                        delay="0s"
                                        duration="2s"
                                        :iteration="1"
                                        :offset="0"
                                        animateClass="animated"
                                >
                                    <img src="/dist/images/work-2.svg" alt="">
                                    <p>{{__('The freight forwarder will deliver the bottle according to your guidelines, the place will be established and the equipment will be checked')}}</p>
                                </u-animate>
                            </li>
                            <li>
                                <u-animate
                                        name="animate__fadeIn"
                                        delay="0s"
                                        duration="3s"
                                        :iteration="1"
                                        :offset="0"
                                        animateClass="animated"
                                >
                                    <img src="/dist/images/work-3.svg" alt="">
                                    <p>{{__('Free Express Shipping:')}}</p>
                                    <p>{{__('from 1 bottle - to Poznań')}}</p>
                                    <p>{{__('from 3 bottles - by region')}}</p>
                                </u-animate>

                            </li>
                        </ul>
                        <ul class="work-block">
                            <li>
                                <u-animate
                                        name="animate__fadeInLeft"
                                        delay="0s"
                                        duration="1s"
                                        :iteration="1"
                                        :offset="0"
                                        animateClass="animated"
                                >
                                    <img src="/dist/images/work-5.svg" alt="">
                                    <p>{{__('Your application')}}</p>
                                </u-animate>

                            </li>
                            <li>
                                <u-animate
                                        name="animate__fadeInLeft"
                                        delay="0s"
                                        duration="3s"
                                        :iteration="1"
                                        :offset="0"
                                        animateClass="animated"
                                >
                                    <img src="/dist/images/work-6.svg" alt="">
                                    <p>{{__('Cost calculation')}}</p>
                                </u-animate>

                            </li>
                            <li>
                                <u-animate
                                        name="animate__fadeInRight"
                                        delay="0s"
                                        duration="2s"
                                        :iteration="1"
                                        :offset="0"
                                        animateClass="animated"
                                >
                                    <img src="/dist/images/work-7.svg" alt="">
                                    <p>{{__('Delivery up to 3 hours')}}</p>
                                </u-animate>

                            </li>
                            <li>
                                <u-animate
                                        name="animate__fadeInRight"
                                        delay="0s"
                                        duration="4s"
                                        :iteration="1"
                                        :offset="0"
                                        animateClass="animated"
                                >
                                    <img src="/dist/images/work-8.svg" alt="">
                                    <p>{{__('You enjoy our water')}}</p>
                                </u-animate>

                            </li>
                        </ul>
                        <div class="block-btn">
                            <a href="tel:+48 510 002 009" class="btn-blue" onclick="gtag_report_conversion()"><span>{{__('Espresso delivery day after day')}}</span></a>
                        </div>
                    </div>
                </div>



                <div class="about">
                    <div class="container">
                        <h2>{{__('About water')}}</h2>
                        <p class="disc">{{__('Mineral composition')}}</p>
                        <div class="content">
                            <div class="left">
                                <u-animate
                                        class="block block-1"
                                        name="animate__zoomIn"
                                        delay="0s"
                                        duration="1s"
                                        :iteration="1"
                                        :offset="0"
                                        animateClass="animated"
                                >
                                    <p>{{__('Iron')}}</p>
                                    <span>&lt;0,040 ± 24% {{__('mg/l')}}</span>
                                </u-animate>

                                <u-animate
                                        class="block block-2"
                                        name="animate__zoomIn"
                                        delay="0s"
                                        duration="2s"
                                        :iteration="1"
                                        :offset="0"
                                        animateClass="animated"
                                >
                                    <p>{{__('Potassium')}}</p>
                                    <span>&lt;0,20 ± 16% {{__('mg/l')}}</span>
                                </u-animate>

                                <u-animate
                                        class="block block-3"
                                        name="animate__zoomIn"
                                        delay="0s"
                                        duration="3s"
                                        :iteration="1"
                                        :offset="0"
                                        animateClass="animated"
                                >
                                    <p>{{__('Calcium')}}</p>
                                    <span>5,3 ± 9,0% {{__('mg/l')}}</span>
                                </u-animate>

                                <u-animate
                                        class=" block-4"
                                        name="animate__zoomIn"
                                        delay="0s"
                                        duration="2s"
                                        :iteration="1"
                                        :offset="0"
                                        animateClass="animated"
                                >
                                    <p>{{__('Ordana')}}</p>
                                    <span>{{__('ph 7,5')}}</span>
                                </u-animate>

                                <u-animate
                                        class="block block-5"
                                        name="animate__zoomIn"
                                        delay="0s"
                                        duration="1s"
                                        :iteration="1"
                                        :offset="0"
                                        animateClass="animated"
                                >
                                    <p>{{__('Fluorides')}}</p>
                                    <span>&lt;0,10 ± 18% {{__('mg/l')}}</span>
                                </u-animate>

                                <u-animate
                                        class="block block-6"
                                        name="animate__zoomIn"
                                        delay="0s"
                                        duration="3s"
                                        :iteration="1"
                                        :offset="0"
                                        animateClass="animated"
                                >
                                    <p>{{__('Magnesium')}}</p>
                                    <span>&lt;0,50 ± 22% {{__('mg/l')}}</span>
                                </u-animate>

                                <u-animate
                                        class="block block-7"
                                        name="animate__zoomIn"
                                        delay="0s"
                                        duration="2s"
                                        :iteration="1"
                                        :offset="0"
                                        animateClass="animated"
                                >
                                    <p>{{__('Sodium')}}</p>
                                    <span>&lt;1,0 ± 15% {{__('mg/l')}}</span>
                                </u-animate>

                                <img src="/dist/images/glass.webp" alt="">
                            </div>
                            <div class="right">
                                <p>{{__('The maximally natural and balanced composition of "Zdrowa Woda" water enriches your body with all vital elements such as magnesium, potassium, calcium, magnesium, sodium, iodine, etc. and is the source of your energy and longevity!')}}</p>
                                <a v-on:click="dialog= true" class="btn-blue"><span>{{__('About the company')}}</span></a>
                            </div>

                        </div>
                        <div class="block-table">
                                <ul>
                                    <li>
                                        <div class="b-1">
                                            <p>{{__('Hydrocarbon (calculated) A')}}</p>
                                        </div>
                                        <div class="b-2">
                                            <p>PN-EN ISO 9963-1:2001+Ap1:2004</p>
                                        </div>
                                        <div class="b-3">
                                            <p>{{__('mg/l')}}</p>
                                        </div>
                                        <div class="b-4">
                                            <p>36,0 ± 10%</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="b-1">
                                            <p>{{__('Total alkalinity A')}} </p>
                                        </div>
                                        <div class="b-2">
                                            <p>PN-EN ISO 9963-1:2001+Ap1:2004</p>
                                        </div>
                                        <div class="b-3">
                                            <p>{{__('mmol/l')}} </p>
                                        </div>
                                        <div class="b-4">
                                            <p>0,59 ± 10%</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="b-1">
                                            <p>{{__('Total iron A')}} </p>
                                        </div>
                                        <div class="b-2">
                                            <p>PN-ISO 6332:2001+Ap1:2016-06</p>
                                        </div>
                                        <div class="b-3">
                                            <p>{{__('mg/l')}}</p>
                                        </div>
                                        <div class="b-4">
                                            <p>&lt;0,040 ± 24%</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="b-1">
                                            <p>{{__('Ammonium ion A')}}  </p>
                                        </div>
                                        <div class="b-2">
                                            <p>PN-EN ISO 14911:2002</p>
                                        </div>
                                        <div class="b-3">
                                            <p>{{__('mg/l')}}</p>
                                        </div>
                                        <div class="b-4">
                                            <p>&lt;0,10 ± 20%</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="b-1">
                                            <p>{{__('Chlorides A')}} </p>
                                        </div>
                                        <div class="b-2">
                                            <p>PN-EN ISO 10304-1:2009+AC:2012</p>
                                        </div>
                                        <div class="b-3">
                                            <p>{{__('mg/l')}}</p>
                                        </div>
                                        <div class="b-4">
                                            <p>1,3 ± 9,0%</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="b-1">
                                            <p>{{__('Fluorides A')}} </p>
                                        </div>
                                        <div class="b-2">
                                            <p>PN-EN ISO 10304-1:2009+AC:2012</p>
                                        </div>
                                        <div class="b-3">
                                            <p>{{__('mg/l')}}</p>
                                        </div>
                                        <div class="b-4">
                                            <p>&lt;0,10 ± 18%</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="b-1">
                                            <p>{{__('Sulfates A')}} </p>
                                        </div>
                                        <div class="b-2">
                                            <p>PN-EN ISO 10304-1:2009+AC:2012</p>
                                        </div>
                                        <div class="b-3">
                                            <p>{{__('mg/l')}}</p>
                                        </div>
                                        <div class="b-4">
                                            <p>3,3 ± 9,0%</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="b-1">
                                            <p>{{__('Magnesium A')}} </p>
                                        </div>
                                        <div class="b-2">
                                            <p>PN-EN ISO 17294-2:2016-11</p>
                                        </div>
                                        <div class="b-3">
                                            <p>{{__('mg/l')}}</p>
                                        </div>
                                        <div class="b-4">
                                            <p>&lt;0,50 ± 22%</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="b-1">
                                            <p>{{__('Potassium A')}} </p>
                                        </div>
                                        <div class="b-2">
                                            <p>PN-EN ISO 17294-2:2016-11</p>
                                        </div>
                                        <div class="b-3">
                                            <p>{{__('mg/l')}}</p>
                                        </div>
                                        <div class="b-4">
                                            <p>&lt;0,20 ± 16%</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="b-1">
                                            <p>{{__('Sodium A')}} </p>
                                        </div>
                                        <div class="b-2">
                                            <p>PN-EN ISO 17294-2:2016-11</p>
                                        </div>
                                        <div class="b-3">
                                            <p>{{__('mg/l')}}</p>
                                        </div>
                                        <div class="b-4">
                                            <p>&lt;1,0 ± 15%</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="b-1">
                                            <p>{{__('Calcium A')}} </p>
                                        </div>
                                        <div class="b-2">
                                            <p>PN-EN ISO 17294-2:2016-11</p>
                                        </div>
                                        <div class="b-3">
                                            <p>{{__('mg/l')}}</p>
                                        </div>
                                        <div class="b-4">
                                            <p>5,3 ± 9,0%</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="b-1">
                                            <p>{{__('Total mineralization N')}} </p>
                                        </div>
                                        <div class="b-2">
                                            <p>{{__('(calculated)')}} </p>
                                        </div>
                                        <div class="b-3">
                                            <p>{{__('mg/l')}}</p>
                                        </div>
                                        <div class="b-4">
                                            <p>47</p>
                                        </div>
                                    </li>
                                </ul>
                        </div>
                        <div class="block-text">
                            <div class="left">
                                <u-animate
                                        class="block"
                                        name="animate__zoomIn"
                                        delay="0s"
                                        duration="1s"
                                        :iteration="1"
                                        :offset="0"
                                        animateClass="animated"
                                >
                                    <p class="name">{{__('Easily absorbed by the human body')}}</p>
                                    <p class="text">{{__('"Zdrowa Woda" guarantees full satisfaction of the bodys physiological needs thanks to its mineral composition and pleasant taste. Water is characterized by a high oxidation-reduction potential (redox potential), which facilitates its absorption by the human body.')}}</p>
                                </u-animate>

                                <u-animate
                                        class="block"
                                        name="animate__zoomIn"
                                        delay="0s"
                                        duration="2s"
                                        :iteration="1"
                                        :offset="0"
                                        animateClass="animated"
                                >
                                    <p class="name">{{__('The quality and safety of "Zdrowa Woda" water is confirmed')}}</p>
                                    <p class="text">{{__('Confirmed by independent research centers:')}}</p>
                                    <p class="li">{{__('FSSC 22000 - Food Safety Management System.')}}</p>
                                    <p class="li">{{__('Aquanet SA liquids to the forefront of the water supply company in Poland. We operate based on the highest quality standards.')}}</p>

                                </u-animate>

                            </div>
                            <div class="right">
                                <u-animate
                                        class="block"
                                        name="animate__zoomIn"
                                        delay="0s"
                                        duration="1s"
                                        :iteration="1"
                                        :offset="0"
                                        animateClass="animated"
                                >
                                    <p class="name">{{__('9 stages of water purification')}}</p>
                                    <p class="text">{{__('This process uses various technologies and filters to help remove various types of contaminants such as mechanical particles, chemicals, bacteria, viruses and other microscopic contaminants. After passing through 7 stages of purification, the water becomes drinkable and has a pleasant taste. The purified water can be used for drinking, cooking, making coffee and tea and other peoples daily needs.')}}</p>
                                </u-animate>

                                <u-animate
                                        class="block"
                                        name="animate__zoomIn"
                                        delay="0s"
                                        duration="2s"
                                        :iteration="1"
                                        :offset="0"
                                        animateClass="animated"
                                >
                                    <p class="name">{{__('International quality and safety control system')}}</p>
                                    <p class="text">{{__('The operation of the entire production process, water supply and customer service is based on the elements of the quality system and control points:')}}</p>
                                    <p class="li">{{__('HACCP (HACCP);')}}</p>
                                    <p class="li">{{__('international quality and safety system')}}</p>
                                    <p class="li">{{__('ISO 22000:2018')}}</p>
                                </u-animate>

                            </div>
                        </div>
                    </div>
                </div>

            </u-animate>

            <ModalDefault width="650" v-if="dialog" v-bind:modal_cancel.sync="dialog">
                <div class="window-site">
                    <h2>{{__('About the company')}}</h2>
                    <p>{{__('"Zdrowa Woda" is a leading supplier of quality drinking water. The company specializes in the bottling and delivery of drinking water that goes through nine stages of purification in order to ensure the high quality of the product.')}}</p>
                    <p>{{__('We use the most modern technologies for cleaning and filtering water, which allows us to remove all possible contamination and microbes from water that can be harmful to human health. In addition, the water undergoes an ionization procedure, which improves its taste properties and adds useful minerals.')}}</p>
                    <p>{{__('We also offer a wide selection of packaging for our products - from small bottles to larger water tanks that meet the various needs of customers. You can order water in a convenient way for you - online on the companys website, using an application for mobile devices or by calling the call center.')}}</p>
                    <p>{{__('Our consumers receive only a quality product. We have a wide range of services that allows us to meet the needs of different categories of customers - from individual customers to business customers.')}}</p>
                    <p>{{__('"Zdrowa Woda" is not just a supplier of drinking water, it is a team of professionals who take care of their customers and provide convenient and high-quality service. The company is always ready to meet customers and help solve any issues.')}}</p>
                    <p>{{__('In addition, "Zdrowa Woda" cares about cooperation with the community and the development of Polish business. The company supports local initiatives and charity projects aimed at developing the Polish economy and improving the lives of local communities.')}}</p>
                    <p>{{__('"Zdrowa Woda" is a reliable and responsible supplier of drinking water that cares about the health and satisfaction of its customers. The company uses the most modern technologies and methods to ensure high product quality and ease of service.')}}</p>
                </div>
             </ModalDefault>

        </u-animate-container>
    </div>
</template>

<script>
    import vAnimateCss from 'v-animate-css';
    import {UAnimateContainer, UAnimate} from 'vue-wow';
import ModalDefault from "../modules/ModalDefault";
export default {
  name: 'home',
    data: function() {
      return {
          data: {},
          selectedCategory: null,
          type: Object,
          required: true,
          narrowImageUrl: "/dist/images/header-1.webp",
          wideImageUrl: "/dist/images/headeer-center-1.webp",
          isWide: false,
          directives: { vAnimateCss },
          dialog: false
      }
  },
  watch: {

  },

    created() {
        this.$http.get('ajax/home')
            .then(response => {
                return response.json();
            })
            .then(response => {
                this.data = response.data;
                this.selectedCategory = this.data.category[0];
            })
            .catch(err => {
                this.httpHandler(err);
            });
        window.addEventListener("resize", this.checkWidth);
        this.checkWidth();
    },
    destroyed() {
        window.removeEventListener("resize", this.checkWidth);
    },
    methods: {
        getProductNameByLanguage(product) {
            const languageCode = this.$store.state.language.lang;
            const productText = product.text.find(item => item.lang === languageCode);
            return productText ? productText.name : this.getProductTextByLanguage(product, 'pl', 'name');
        },

        getProductDescriptionByLanguage(product) {
            const languageCode = this.$store.state.language.lang;
            const productText = product.text.find(item => item.lang === languageCode);
            return productText ? productText.description : this.getProductTextByLanguage(product, 'pl', 'description');
        },

        getProductTextByLanguage(product, lang, field) {
            const fallbackText = product.text.find(item => item.lang === lang);
            return fallbackText ? fallbackText[field] : '';
        },

        selectCategory(category) {
            this.selectedCategory = category;
        },
        checkWidth() {
            this.isWide = window.innerWidth > 900;
        },
    },
  computed: {
      languages() {
          return this.$store.state.language.list;
      },

  },
  mounted() {

  },
  components: {
      UAnimateContainer,
      UAnimate,
      ModalDefault
  }
}
</script>
