<template>
  <div class="content-notFound">
      <div class="content">
        <div class="left">
           <router-link :to="{name: 'home'}"><img src="/dist/images/404-img.png" alt=""></router-link>
           <p>{{__('Oops, We can seem to find the page what you are looking for.')}}</p>
           <router-link :to="{name: 'home'}" class="btn-site">{{__('go to homepage')}}</router-link>
        </div>  
      </div>
  </div>
</template>

<script>

    export default {
        name: 'NotFound',
        data () {
            return {

            }
        },
        computed: {

        },
        asyncComputed: {

        },
        methods: {
        },
        components: {

        },

    }
</script>
