<template>
    <div class="sign-in">
            <div class="content">
                <div class="right">
                    <div class="wrap-head">
                        <router-link :to="{name: 'home'}"><img src="/dist/images/logo-black.svg" class="logo" alt=""></router-link>
                        <h1 class="text-darker ">{{__('Welcome back')}}</h1>
                    </div>
                    <div>
                        <div class="wrap-input">
                            <p class="text semi">{{__('Email')}}</p>
                            <InputLabelTextDefault
                                    :placeholder="__('Enter your email')"
                                    color="main"
                                    v-model="email"
                                    v-bind:errors="formValidate.email"
                                    type="email"
                                    name="email"
                                    id="email"
                                    @change="updateValidate('email')"
                            ></InputLabelTextDefault>
                        </div>
                        <div class="wrap-input">
                            <p class="text semi">{{__('Password')}}</p>
                            <InputLabelTextDefault
                                :placeholder="__('Enter your password')"
                                color="main"
                                type="password"
                                v-model="password"
                                v-bind:errors="formValidate.password"
                                @change="updateValidate('password')"

                            ></InputLabelTextDefault>
                        </div>
                        <div class="forgot">
                            <router-link
                              :to="{name: 'forgot'}"
                              class=""
                            >{{__('Forgot password?')}}
                            </router-link>
                        </div>
                        <div class="wrap-sing">
                            <v-btn
                                    height="46"
                                    class="btn-site"
                                    v-on:click="openCaptcha"
                                    :loading="submitDisabled"
                            >
                              <span>
                                {{__('Log in to account')}}
                              </span>
                            </v-btn>
                        </div>
                        <div class="social-mob-login">
                            <p class="text-secondary ">{{__('Have not an account yet?')}}
                              <router-link
                                    :to="{name: 'register'}"
                                    class="text-primary bold"
                              >
                                  {{__('Sign Up')}}
                              </router-link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        <Captcha v-if="captcha" @onSubmit="onSubmit"></Captcha>
    </div>
</template>


<script>
    import InputLabelTextDefault from "../modules/InputLabelTextDefault";
    import Captcha from "../modules/Captcha";
    import {
        required,
        email,
        maxLength,
        minLength,
    } from "vuelidate/lib/validators";

    export default {
        name: "AuthLogin",
        data() {
            return {
                captcha: false,
                email: null,
                password: null,
                submitDisabled: false,
                formValidate: {
                    email: null,
                    password: null,
                },
                rules: this.validateMessage,
            };
        },
        props: {
            socialAuth: {
                type: Array,
            },
        },
        validations: {
            email: {
                required,
                email,
            },
            password: {
                required,
                maxLength: maxLength(50),
                minLength: minLength(6),
            },
        },
        mounted () {

        },
        methods: {
            updateValidate: function (type) {
                // let app= this.formValidate;
                //
                // let validate= this.$v[type];
                // if(validate.$invalid) {
                //     app[type]= [];
                //     for(var prop in validate.$params) {
                //         if(!validate[prop]) {
                //             let msg= this.__(this.rules[prop], validate.$params[prop]);
                //             app[type].push(msg);
                //         }
                //     }
                // } else {
                //     app[type]= [];
                // }
                this.formValidate = this.$changeValidate(
                    this.formValidate,
                    this.$v[type],
                    type
                );
            },
            openCaptcha() {
                let valid = this.$v;
                if (valid.$invalid) {
                    let massages = {
                        email: [],
                        password: [],
                    };

                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    this.captcha= true;
                }
            },
            onSubmit: function (session, arrayData) {
                this.captcha= false;
                let valid = this.$v;

                if (valid.$invalid) {
                    let massages = {
                        email: [],
                        password: [],
                    };

                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled = true;
                    const response = {
                        email: this.email,
                        password: this.password,
                        challenge: session,
                    };
                    response[session]= arrayData;

                    this.$http
                        .post("ajax/login", response)
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {

                            this.submitDisabled= false;
                            if(response.data.security) {
                                this.$store.dispatch('asyncAuth', response.data);
                                if(this.$route.params.page != 'security') {
                                  this.$router.push({name: 'security'});
                                }
                            } else {
                                // this.$store.commit('updateAuth', false);
                                // this.$router.push({name: 'dashboard'});
                                window.loginUser= true;
                                location.reload();
                            }
                        })
                        .catch((err) => {
                            this.captcha= false;
                            this.submitDisabled = false;
                            let response = this.httpHandler(err);
                            if (response.error) {
                                this.formValidate = response.error.message;
                            }
                        });
                }
            },
        },
        components: {
            Captcha,
            InputLabelTextDefault,
        },
    };
</script>

<style lang="css" scoped>


</style>
