<template>
    <div class="coming-soon">
        <div class="content">
            <img src="/dist/images/coming-soon.png" alt="">
            <h2>{{__('Coming soon!')}}</h2>
            <span>{{__('This page is in development and will appear soon.')}}</span>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'ComingSoon',
        data () {
            return {

            }
        },
        computed: {

        },
        asyncComputed: {

        },
        methods: {
        },
        components: {

        },

    }
</script>
