<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
    export default {
        data: function() {
            return {

            }
        },
        name: 'Authentication',
        computed: {

        },
        components: {

        }
    }


</script>

<style scoped>

</style>