<template>
    <u-animate-container >
        <u-animate
                delay="0s"
                duration="1s"
                :iteration="1"
                :offset="0"
                animateClass="animated"
        >
            <div class="footer-main">

                <div class="container">
                    <div class="left">
                        <router-link :to="{name: 'home'}">
                            <img src="/dist/images/logo.svg" alt="">
                        </router-link>

                        <p class="text">Zdrowa Woda.eco SP. Z O.O.</p>
                        <p class="text">Ul. Bukowska 18</p>
                        <p class="text">Poznań; Wielkopolskie; Kod pocztowy: 60-811</p>
                        <p class="text">KRS 0001004955, REGON 523772781, NIP 8971914942</p>
                    </div>

                    <div class="center">
                        <div class="block">
                            <p>{{__('Free shipping')}}</p>
                            <span>+48 510-002-009</span>
                        </div>
                        <div class="text-time">
                            <p class="text">{{__('Acceptance of orders:')}}</p>
                            <p class="time">{{__('Mon-Fri: 9:00 - 18:00')}}</p>
                            <p class="time">{{__('Sat: 10:00 - 13:00')}}</p>
                            <p class="time">{{__('Sun: closed')}}</p>
                        </div>
                    </div>

                    <div class="right">
                        <a class="btn-site" href="tel:+48 510 002 009" onclick="gtag_report_conversion()">
                            <span>
                                <img src="/dist/images/btn-img.svg" alt="">
                                {{__('Order water')}}
                            </span>
                        </a>
                        <p class="href">
                            <span class="tt">
                                 <router-link :to="{name: 'umowa'}" target="_blank">{{__('GENERAL CONDITIONS OF THE SALES AND SUPPLY AGREEMENT OF BOTTLED WATER ZDROWA WODA.ECO')}}</router-link>
                            </span>
                            <span class="p">
                                <router-link :to="{name: 'privacy'}" target="_blank">{{__('Privacy policy')}}</router-link>
                            </span>
                            <span>
                                <router-link :to="{name: 'terms'}" target="_blank">{{__('Terms of Service')}}</router-link>
                            </span>
                        </p>
                    </div>
                </div>

            </div>
        </u-animate>
    </u-animate-container >

</template>

<script>
    import vAnimateCss from 'v-animate-css';
    import {UAnimateContainer, UAnimate} from 'vue-wow';
    export default {
        name: 'HomeFooter',
        data: function() {
            return {
                directives: { vAnimateCss },
            }
        },

        computed: {

        },
        created() {

        },
        components: {
            UAnimateContainer,
            UAnimate,
        }
    }


</script>

<style scoped>

</style>