export default {
    state: {
        portfolio: null,
        payments: null,
        portfolio_detail: null,
        user: null,
    },
    getters: {

    },
    actions: {
        asyncPortfolio(context, payload) {
            context.commit('updatePortfolio', payload);
        },
        asyncPayments(context, payload) {
            context.commit('updatePayments', payload);
        },
        asyncPortfolioDetail(context, payload) {
            context.commit('updatePortfolioDetail', payload);
        },
        asyncPortfolioBalance(context, payload) {
            context.commit('updatePortfolioBalance', payload);
        },
        asyncUserInfo(context, payload) {
            context.commit('updateUserInfo', payload.data);
        },
        asyncUserAvatar(context, payload) {
            context.commit('updateUserAvatar', payload);
        },
        userWalletId(context, payload) {
            context.commit('updateUserWalletId', payload);
        },
        userStoreId(context, payload) {
            context.commit('updateUserStoreId', payload);
        }
    },
    mutations: {
        updateUserInfo(state, data) {
            this.state.auth.user= data;
        },
        updateUserInfoPayload(state, data) {
            for(let key in data) {
                this.state.auth.user[key]= data[key];
            }
        },
        updateUserWalletId(state, wallet_id) {
            this.state.auth.user.wallet_id= wallet_id;
        },
        updateUserStoreId(state, store_id) {
            this.state.auth.user.store_id= store_id;
        },
        updatePortfolio(state, data) {
            this.state.auth.portfolio= data;
        },
        updateUserAvatar(state, data) {
            this.state.auth.portfolio.photo= data;
        },

        updatePayments(state, data) {
            this.state.auth.payments= data;
        },
        updatePortfolioDetail(state, data) {
            this.state.auth.portfolio_detail= data;
        },
        updatePortfolioBalance(state, data) {
            this.state.auth.portfolio.data[data.key].info.balance= data.balance;
        },

    }
}