<template>
    <div class="home-page text-pages privacy-page">
        <div class="container" v-if="privacy">
            <div v-html="privacy.text"></div>
            <h1>Polityka Prywatności Aplikacji Zdrowa Woda.eco</h1>
            <p class="text m">Data wejścia w życie: 01.12.2023</p>
            <p class="text b">1. Wstęp</p>
            <p class="text">Witamy w aplikacji Zdrowa Woda.eco, opracowanej i utrzymywanej przez firmę Zdrowa Woda.eco (dalej „my”, „nas”, „nasza firma”). Niniejsza Polityka Prywatności ma na celu informowanie Państwa o sposobie zbierania, wykorzystywania, przetwarzania i ochrony Państwa danych osobowych podczas korzystania z naszej aplikacji.</p>

            <p class="text b">2. Zbieranie informacji</p>

            <p class="text bb">2.1. Informacje podane przez użytkownika:</p>
            <p class="text">Dane rejestracyjne: Gdy rejestrują Państwo konto, prosimy o podanie imienia, adresu e-mail i hasła. Dane profilu: Mogą Państwo dostarczyć dodatkowe informacje w swoim profilu, takie jak zdjęcie, numer telefonu i adres.</p>

            <p class="text bb">2.2. Informacje zbierane automatycznie:</p>
            <p class="text">Użytkowanie aplikacji: Zbieramy informacje o tym, jak i kiedy używają Państwo naszej aplikacji, włącznie z Państwa działaniami wewnątrz aplikacji. Informacje techniczne: Zbieramy informacje o urządzeniu i połączeniu, takie jak model urządzenia, system operacyjny, unikalne identyfikatory urządzeń i adres IP.</p>

            <p class="text b">3. Wykorzystanie Państwa danych</p>
            <p class="text">Wykorzystujemy zebrane informacje do następujących celów:</p>
            <p class="text">Zapewnienie i ulepszanie funkcjonalności aplikacji.</p>
            <p class="text">Zarządzanie Państwa kontem i świadczenie wsparcia dla użytkowników.</p>
            <p class="text">Analiza użytkowania aplikacji w celu poprawy doświadczeń użytkowników.</p>
            <p class="text">Komunikacja z Państwem, włączając wysyłanie newsletterów i informacyjnych biuletynów.</p>

            <p class="text b">4. Udostępnianie informacji</p>
            <p class="text">Nie dzielimy się Państwa danymi osobowymi z osobami trzecimi, z wyjątkiem następujących przypadków:</p>
            <p class="text">Za zgodą użytkownika: Możemy udostępnić Państwa dane osobom trzecim po otrzymaniu wyraźnej zgody.</p>
            <p class="text">W celu przetwarzania danych: Możemy przekazywać informacje naszym zaufanym partnerom, którzy przetwarzają dane w naszym imieniu.</p>
            <p class="text">Na żądanie prawa: W przypadku żądania prawnego lub w celu zgodności z wymogami prawnymi.</p>

            <p class="text b">5. Bezpieczeństwo danych</p>
            <p class="text">Podejmujemy środki w celu ochrony Państwa danych osobowych przed nieautoryzowanym dostępem, wykorzystaniem, zmianą i zniszczeniem.</p>

            <p class="text b">6. Państwa prawa</p>
            <p class="text">Mają Państwo prawo do dostępu, poprawienia, usunięcia lub ograniczenia przetwarzania Państwa danych osobowych. W tym celu prosimy o kontakt z nami za pomocą poniższych informacji kontaktowych.</p>

            <p class="text b">7. Zmiany w Polityce Prywatności</p>
            <p class="text">Zastrzegamy sobie prawo do zmiany niniejszej Polityki Prywatności. Wszelkie zmiany zostaną opublikowane w aplikacji i wejdą w życie od momentu publikacji.</p>

            <p class="text b">8. Kontakt</p>
            <p class="text">Jeśli mają Państwo pytania dotyczące niniejszej Polityki Prywatności lub przetwarzania Państwa danych, prosimy o kontakt z nami pod adresem <a href="mailto:support@woda.eco">support@woda.eco</a> lub <a href="tel:+48 510 002 009">+48 510 002 009</a>.</p>

        </div>
    </div>
</template>

<script>
export default {
  name: 'privacy',
    data: function() {
      return {
          privacy: ''
      }
  },

  watch: {
      lang() {
    }
  },
  created() {
      this.$http.get('ajax/pages/privacy')
          .then(response => {
              return response.json();
          })
          .then(response => {
              // Сохранение полученных данных в переменную privacy
              this.privacy = response.data;
          })
          .catch(err => {
              this.httpHandler(err);
          });
  },
  methods:{

  },
  computed: {
      portfolio() {
          return this.$store.state.auth.portfolio;
      },
      languages() {
          return this.$store.state.language.list;
      },

  },
  mounted() {

  },
  components: {

  }
}
</script>
