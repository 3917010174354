<template>
  <div class="content-site">

  </div>
</template>

<script>
    export default {
        data: function() {
            return {

            }
        },
        name: 'Office',
        computed: {

        },
        watch: {

        },
        methods: {

        },
        created() {

        },
        components: {

        }
    }


</script>

<style scoped>

</style>
