<template>
    <div class="sign-in">
            <div class="content">
                <div class="right">
                    <div class="wrap-head">
                        <router-link :to="{name: 'home'}"><img src="/dist/images/logo-black.svg" class="logo" alt=""></router-link>
                        <h1 class="text-darker ">{{__('Create your Zdrowa.Woda Account')}}</h1>
                    </div>
                    <div class="text-left">
                        <div class="wrap-input form-row">
                            <p class="text semi">{{__('Name')}}</p>
                            <InputLabelTextDefault
                                :placeholder="__('Enter your name')"
                                color="main"
                                v-model="name"
                                v-bind:errors="formValidate.name"
                                @change="updateValidate('name')"
                            ></InputLabelTextDefault>
                            <!--<label for="email">Email address</label>-->
                            <!--<v-text-field label="Your email"></v-text-field>-->
                        </div>
                        <div class="wrap-input">
                            <p class="text semi">{{__('Email')}}</p>
                            <InputLabelTextDefault
                                :placeholder="__('Enter your email')"
                                color="main"
                                v-model="email"
                                v-bind:errors="formValidate.email"
                                @change="updateValidate('email')"
                                type="email"
                                name="email"
                            ></InputLabelTextDefault>
                        </div>
                        <div class="wrap-input password">
                            <p class="text semi">{{__('Password')}}</p>
                            <InputLabelTextDefault
                                :placeholder="__('Enter your password')"
                                color="main"
                                type="password"
                                v-model="password"
                                v-bind:errors="formValidate.password"
                                @change="updateValidate('password')"
                            ></InputLabelTextDefault>
                        </div>
                        <div class="wrap-input password">
                            <p class="text semi">{{__('Confirm Password')}}</p>
                            <InputLabelTextDefault
                                    :placeholder="__('Enter your password')"
                                    color="main"
                                    type="password"
                                    v-model="new_password"
                                    v-bind:errors="formValidate.new_password"
                                    @change="updateValidate('new_password')"
                            ></InputLabelTextDefault>
                        </div>
                        <div class="wrap-input upline">
                            <p class="text semi">{{__('Your upline')}}</p>
                            <InputLabelTextDefault
                                    color="main"
                                    v-model="referral"
                                    v-bind:errors="formValidate.referral"
                                    @change="updateValidate('referral')"
                            ></InputLabelTextDefault>
                        </div>
                        <div class="sign-text">
                            <div class="checkbox-site">
                                <v-checkbox v-model="sign"></v-checkbox>
                            </div>
                            <p>{{__('I got acquainted')}} <router-link :to="{name: 'privacy'}">{{__('Rules of participation')}}</router-link> {{__('in Zdrowa.Woda and fully realize all the risks associated with participation')}}</p>
                        </div>
                        <div class="input-sing">
                            <div class="wrap-sing">
                                <v-btn
                                    height="46"
                                    class="btn-site"
                                    v-on:click="openCaptcha"
                                    :loading="submitDisabled"
                                    :disabled="!sign"
                                >
                                    <span>
                                      {{__('create account')}}
                                    </span>
                                </v-btn>
                            </div>
                        </div>
                        <div class="social-mob-login">
                            <p class="text-secondary">{{__('Already have an account?')}}
                                <router-link :to="{name: 'login'}" class="text-primary bold">{{__('Log In')}}</router-link>
                            </p>
                        </div>
                    </div>
                </div>
        </div>
        <Captcha v-if="captcha" @onSubmit="onSubmit"></Captcha>
    </div>
</template>


<script>

    import Captcha from "../modules/Captcha";
    import InputLabelTextDefault from "../modules/InputLabelTextDefault";
    import {
        required,
        email,
        maxLength,
        minLength,
        numeric,
        sameAs
    } from "vuelidate/lib/validators";

    export default {
        name: "AuthSignup",
        data() {
            return {
                captcha: false,
                name: null,
                sign: true,
                email: null,
                password: null,
                new_password: null,
                submitDisabled: false,
                referral: window.referral,
                formValidate: {
                    email: null,
                    name: null,
                    password: null,
                    new_password: null,
                    upline: null,
                },
            };
        },
        props: {
            label: {
                type: String,
            },
        },
        validations: {
            name: {
                required,
                maxLength: maxLength(50),
                minLength: minLength(3),
            },
            email: {
                required,
                email,
            },
            password: {
                required,
                maxLength: maxLength(50),
                minLength: minLength(6),
            },
            new_password: {
                required,
                sameAsPassword: sameAs(function() {
                    if(this.new_password && this.new_password.length) {
                        return (this.new_password== this.password) ? this.new_password : false;
                    } else {
                        return this.new_password;
                    }
                }),
            },
            sign: {
                required
            },
            referral: {
                required,
                numeric
            }
        },
        methods: {

            updateValidate: function (type) {
                this.formValidate = this.$changeValidate(
                    this.formValidate,
                    this.$v[type],
                    type
                );
                // this.$nextTick(() => {
                // });
            },
            openCaptcha() {
                let valid = this.$v;

                if (valid.$invalid || !this.sign) {
                    let massages = {
                        name: [],
                        email: [],
                        password: [],
                        new_password: [],
                        sign: [],
                        referral: [],
                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    this.captcha= true;
                }
            },
            onSubmit: function (session, arrayData) {
                let valid = this.$v;

                if (valid.$invalid) {
                    let massages = {
                        name: [],
                        email: [],
                        password: [],
                        new_password: [],
                        referral: [],
                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled = true;
                    const response = {
                        name: this.name,
                        email: this.email,
                        password: this.password,
                        referral: this.referral,
                        password_confirmation: this.new_password,
                        challenge: session,
                    };
                    response[session]= arrayData;

                    this.$http
                        .post("ajax/register", response)
                        .then((response) => {
                            return response.json();
                        })
                        .then(() => {
                            location.reload();
                        })
                        .catch((err) => {
                            this.submitDisabled = false;
                            let response = this.httpHandler(err);
                            this.captcha= false;
                            if (response.error) {
                                this.formValidate = response.error.message;
                            }
                        });
                }
            },
        },
        computed: {
            page: function () {
                return this.$route.name;
            },
        },
        components: {
            InputLabelTextDefault,
            Captcha
        },
    };
</script>

<style lang="scss" scoped>

</style>