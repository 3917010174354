import Vue from 'vue';
import VueRouter from "vue-router";
import Main from "../components/Main";
import Office from "../components/Office";
import Authentication from "../components/Authentication";
import Home from "../components/pages/Home";
import AuthSignup from "../components/pages/AuthSignup";
import AuthLogin from "../components/pages/AuthLogin";
import AuthForgot from "../components/pages/AuthForgot";
import AuthResetPass from "../components/pages/AuthResetPass";
import AuthSecurity from "../components/pages/AuthSecurity";
import NotFound from '../components/pages/NotFound';
import ComingSoon from '../components/pages/ComingSoon';
import Terms from "../components/pages/Terms";
import Privacy from "../components/pages/Privacy";
import Umowa from "../components/pages/Umowa";
import Instruction from "../components/pages/Instruction";


import store from "../store/index";
import middlewarePipeline from "./middlewarePipeline";
import guest from "./middleware/guest";
import auth from "./middleware/auth";

Vue.use(VueRouter);
const routes= new VueRouter({
    mode: 'history',
    linkActiveClass: 'active',
    routes: [
        {
            path: '/',
            component: Main,
            children: [
                {
                    path: (process.env.NODE_ENV === 'production') ? '/' : '/dist/',
                    name: 'home',
                    component: Home,
                    meta: {
                        title: 'Dostawa wody pitnej',
                    },

                },
                {
                    path: '/terms',
                    name: 'terms',
                    component: Terms,
                    meta: {
                        title: 'Terms',
                    },

                },
                {
                    path: '/privacy',
                    name: 'privacy',
                    component: Privacy,
                    meta: {
                        title: 'Privacy',
                    },

                },
                {
                    path: '/umowa',
                    name: 'umowa',
                    component: Umowa,
                    meta: {
                        title: 'Umowa',
                    },

                },
                {
                    path: '/instruction',
                    name: 'instruction',
                    component: Instruction,
                    meta: {
                        title: 'Instruction',
                    },

                },

            ]
        },
        {
            path: '/authentication',
            component: Authentication,
            children: [
                {
                    path: '/registration',
                    name: 'register',
                    component: AuthSignup,
                    meta: {
                        title: 'Registration',
                        middleware: [
                            guest
                        ]
                    },
                },
                {
                    path: '/login',
                    name: 'login',
                    component: AuthLogin,
                    meta: {
                        title: 'Login',
                        middleware: [
                            guest
                        ]
                    },
                },
                {
                    path: '/forgot',
                    name: 'forgot',
                    component: AuthForgot,
                    meta: {
                        title: 'Forgot',
                        middleware: [
                            guest
                        ]
                    },
                },
                {
                    path: '/security',
                    name: 'security',
                    component: AuthSecurity,
                    meta: {
                        title: 'Authentication security',
                        middleware: [
                            guest
                        ]
                    },
                },
                {
                    path: '/ajax/password/reset/:token',
                    name: 'password_reset',
                    component: AuthResetPass,
                    meta: {
                        title: 'Reset password',
                        middleware: [
                            guest
                        ]
                    },
                },
            ]
        },
        {
            path: '/office',
            component: Office,
            children: [
                {
                    path: '/office/staking',
                    name: 'staying',
                    component: ComingSoon,
                    meta: {
                        title: 'Coming Soon',
                        middleware: [
                            auth
                        ]
                    },
                },
                // {
                //     path: '/office/products',
                //     name: 'products',
                //     component: ComingSoon,
                //     meta: {
                //         title: 'Coming Soon',
                //         middleware: [
                //             auth
                //         ]
                //     },
                // },



            ]

        },
        {
            path: '/logout',
            name: 'logout',
        },
        {
            path: '*',
            name: 'not_found',
            component: NotFound,
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition || to.meta=='tab') {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});


routes.beforeEach((to, from, next) => {
    let title = "Zdrowa Woda";

    if (to.meta.title) {
        document.title = title + ' | ' + to.meta.title
    } else {
        document.title = title
    }


    if (!to.meta.middleware) {
        return next();
    }
    const middleware = to.meta.middleware;

    const context = {
        to,
        from,
        next,
        store
    };


    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    });
});


export default routes;
