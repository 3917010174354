<template>
    <div class="home-page text-pages privacy-page umowa-page">
        <div class="container" v-if="instruction">
            <div v-html="instruction.text"></div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'instruction',
    data: function() {
      return {
          instruction: ''
      }
  },

  watch: {
      lang() {
    }
  },
  created() {
      this.$http.get('ajax/pages/instruction')
          .then(response => {
              return response.json();
          })
          .then(response => {
              // Сохранение полученных данных в переменную privacy
              this.instruction = response.data;
          })
          .catch(err => {
              this.httpHandler(err);
          });
  },
  methods:{

  },
  computed: {
      languages() {
          return this.$store.state.language.list;
      },

  },
  mounted() {

  },
  components: {

  }
}
</script>
