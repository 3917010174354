<template>
    <div class="home-menu">
        <div class="container">
            <div class="left">
                <router-link :to="{name: 'home'}"><img src="/dist/images/logo.svg" alt=""></router-link>
                <DropdownLanguages></DropdownLanguages>
            </div>
            <div class="right">
                <a href="tel:+48 510 002 009" class="btn-site" onclick="gtag_report_conversion()">
                    <span>
                        <img src="/dist/images/btn-img.svg" alt="">
                        {{__('Order water')}}
                    </span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import DropdownLanguages from "./modules/DropdownLanguages";
    export default {
        name: 'HomeMenu',
        data: function() {
            return {

            }
        },
        methods:{

        },
        computed: {

        },
        created () {

        },
        destroyed () {

        },
        components: {
            DropdownLanguages

        }
    }


</script>

<style scoped>

</style>