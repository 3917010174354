<template>
    <div class="sign-in" v-if="$route.query.email">
            <div class="content">
                <div class="right">
                    <div class="wrap-head">
                        <router-link :to="{name: 'home'}"><img src="/dist/images/logo-black.svg" class="logo" alt=""></router-link>
                        <h1 class="text-darker ">{{__('Password reset')}}</h1>
                    </div>
                    <div>
                        <div class=" wrap-input">
                            <p class="text semi">{{__('Email')}}</p>
                            <InputLabelTextDefault :placeholder="__('Enter your email')" disabled :value="$route.query.email" type="email" name="email"></InputLabelTextDefault>
                        </div>
                        <div class=" wrap-input">
                            <p class="text semi">{{__('Password')}}</p>
                            <InputLabelTextDefault :placeholder="__('New password')" type="password" v-model="password" v-bind:errors="formValidate.password" @change="updateValidate('password')"></InputLabelTextDefault>
                        </div>
                        <div class=" wrap-input">
                            <p class="text semi">{{__('Password')}}</p>
                            <InputLabelTextDefault :placeholder="__('Confirmation Password')" type="password" v-model="password_confirmation" v-bind:errors="formValidate.password_confirmation" @change="updateValidate('password_confirmation')"></InputLabelTextDefault>
                        </div>
                        <div class=" wrap-sing">
                            <v-btn
                                height="46"
                                style="display: block"
                                class="btn-site"
                                :loading="submitDisabled"
                                block
                                success
                                v-on:click="openCaptcha"
                            >
                                <span>{{__('Send')}}</span>
                            </v-btn>
                        </div>
                    </div>
                </div>

            </div>
        <Captcha v-if="captcha" @onSubmit="onSubmit"></Captcha>
    </div>
    <div v-else>
      {{$router.push({name: 'home'})}}
    </div>
</template>


<script>
    import Captcha from "../modules/Captcha";
    import InputLabelTextDefault from "../modules/InputLabelTextDefault";
    import {required, maxLength, minLength, sameAs} from "vuelidate/lib/validators";

    export default {
        name: "AuthResetPass",
        data() {
            return {
                captcha: false,
                password: null,
                password_confirmation: null,
                submitDisabled: false,
                formValidate: {
                    password: null,
                    password_confirmation: null,
                },
                rules: this.validateMessage,
                dateUpdate: null
            };
        },
        props: {
            modal: {
                type: Object
            }
        },
        validations: {
            password: {
                required,
                maxLength: maxLength(50),
                minLength: minLength(3),
            },
            password_confirmation: {
                sameAsPassword: sameAs(function() {
                    if(this.password_confirmation.length) {
                        return (this.password_confirmation== this.password) ? this.password_confirmation : false;
                    } else {
                        return this.password_confirmation;
                    }
                }),
            },
        },
        methods: {
            updateValidate: function(type) {
                this.formValidate = this.$changeValidate(this.formValidate, this.$v[type], type);
            },
            openCaptcha() {
                let valid= this.$v;

                if(valid.$invalid) {
                    let massages= {
                        'password': [],
                        'password_confirmation': [],
                    };
                    this.formValidate= this.$formValidate(massages, valid);
                } else {
                    this.captcha= true;
                }
            },
            onSubmit: function (session, arrayData) {
                let valid= this.$v;

                if(valid.$invalid) {
                    let massages= {
                        'password': [],
                        'password_confirmation': [],
                    };
                    this.formValidate= this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled= true;

                    const response ={
                        email: this.$route.query.email,
                        token: this.$route.params.token,
                        password: this.password,
                        password_confirmation: this.password_confirmation,
                        challenge: session,
                    };
                    response[session]= arrayData;
                    this.$http.post('ajax/password/update', response)
                        .then(response=> {
                            return response.json()
                        })
                        .then(()=> {
                            this.captcha= false;
                            this.$router.push({name: 'login'});
                        }).catch(err => {
                            this.captcha= false;
                            this.submitDisabled= false;
                            let response= this.httpHandler(err);
                            if(response.error) {
                                this.formValidate = response.error.message;

                            }
                        });
                }
            },
        },
        components: {
            InputLabelTextDefault,
            Captcha
        }
    };
</script>

<style lang="scss" scoped>


</style>