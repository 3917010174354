export default {
    state: {
        auth: null,

    },
    getters: {

    },
    actions: {
        asyncAuth(context, payload) {
            context.commit('updateAuth', payload);
        },
    },
    mutations: {
        updateAuth(state, data) {
            this.state.preference.auth= data;
        },
    }
}
