<template>
  <div v-if="dialog">
    <v-row justify="center">
      <v-dialog
        v-model="dialog" persistent :max-width="width"
        v-on:click:outside="modalClose"
      >
        <div class="white " style="position: relative">

          <span class="close" v-if="close" v-on:click="modalClose">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.00081 6.00008L11.2438 11.2431M0.757812 11.2431L6.00081 6.00008L0.757812 11.2431ZM11.2438 0.75708L5.99981 6.00008L11.2438 0.75708ZM5.99981 6.00008L0.757812 0.75708L5.99981 6.00008Z"  stroke-opacity="0.7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>
          <slot></slot>

        </div>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
    export default {

        name: 'ModalDefault',
        data: function() {
            return {
                dialog: true,
                submitDisabled: false
            }
        },
        props: {
            modal_cancel: {
                type: [Boolean, Object, Array, String, Number]
            },
            width: {
                type: String,
                default: '540px'
            },
            close: {
                type: Boolean,
                default: true
            }

        },
        computed: {

        },
        watch: {

        },
        methods: {
            modalClose: function () {
                this.$emit('modalCancel', this.modal_cancel);
                this.$emit('update:modal_cancel', false);
            }
        },
        components: {
        },
    };
</script>
