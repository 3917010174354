<template>
    <div class="sign-in">
        <div class="container">
            <div class="content">
                <div class="right" v-if="authParams">
                    <div>
                        <router-link :to="{name: 'home'}"><img src="/dist/images/logo-black.svg" class="logo" alt=""></router-link>
                        <h1 class="text-darker">2FA</h1>
                    </div>
                    <div>
                        <div class="wrap-input">
                            <p class="text semi">{{__('OTP Code')}}</p>
                            <InputLabelTextDefault
                                    color="main"
                                    v-model="authenticator"
                                    v-bind:errors="formValidate.authenticator"
                                    @change="updateValidate('authenticator')">

                            </InputLabelTextDefault>
                        </div>
                        <div class=" wrap-sing">
                            <v-btn
                                height="46"
                                class="btn-site"
                                :loading="submitDisabled"
                                v-on:click="onSubmit">
                                <span>{{__('Confirm')}}</span>
                            </v-btn>
                            <a v-if="authParams.security.email && dateUpdate=== false" class="mt-3 d-block">{{__('loading...')}}</a>
                            <a v-else-if="authParams.security.email && !dateUpdate" class="mt-3 d-block color-text btn-site" @click="sendCode">{{__(((send) ? 'Resend' : 'Send')+'  code')}}</a>
                            <p v-else-if="dateUpdate" class="mt-3 d-block color-text btn-site">{{__('Next resend:')+' '+dateUpdate}}</p>
                        </div>
                    </div>
                </div>
                <div v-else>
                  {{$router.push('login')}}
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import InputLabelTextDefault from "../modules/InputLabelTextDefault";
    import {required, maxLength, minLength, integer} from "vuelidate/lib/validators";

    export default {
        name: "AuthSecurity",
        data() {
            return {
                submitDisabled: false,
                authenticator: null,
                send: false,
                // send: (this.authParams.security['email'] && !this.authParams.security['2fa']) ? true : false,
                formValidate: {
                    authenticator: null,
                },
                rules: this.validateMessage,
                dateUpdate: null
            };
        },
        computed: {
            authParams() {
                return this.$store.state.preference.auth;
            },
        },
        props: {

        },
        validations: {
            authenticator: {
                required,
                maxLength: maxLength(8),
                minLength: minLength(4),
                integer
            },
        },
        methods: {
            sendCode: function() {
                this.dateUpdate= false;
                this.$http.get('ajax/resend_code')
                    .then(response=> {
                        return response.json()
                    })
                    .then(response=> {
                        this.dateUpdate= response.data.dateUpdate;
                        let refreshIntervalId = setInterval(()=> {
                            if(this.dateUpdate > 0) {
                                this.dateUpdate= this.dateUpdate-1;
                            } else {
                                clearInterval(refreshIntervalId);
                            }
                        }, 1000);
                        this.send= true;
                    }).catch(err => {
                    let response= this.httpHandler(err);
                    if(response.error) {
                        this.formValidate = response.error.message;
                    }
                });
            },
            updateValidate: function(type) {
                this.formValidate = this.$changeValidate(this.formValidate, this.$v[type], type);
            },
            onSubmit: function (session, arrayData) {
                let valid= this.$v;

                if(valid.$invalid) {
                    let massages= {
                        'authenticator': [],
                    };
                    this.formValidate= this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled= true;
                    const response ={
                        authenticator: this.authenticator,
                        challenge: session,
                    };
                    response[session]= arrayData;

                    this.$http.post('ajax/authenticator', response)
                        .then(response=> {
                            return response.json()
                        })
                        .then(()=> {
                            location.reload();
                        }).catch(err => {
                            this.submitDisabled= false;
                            let response= this.httpHandler(err);
                            if(response.error) {
                                this.formValidate = response.error.message;
                            }
                        });
                }
            },
        },
        components: {
            InputLabelTextDefault,
        }
    };
</script>

<style lang="scss" scoped>


</style>